.modal-50p{
    max-width: none !important;
    width: 50%;
}

.modal-60p{
    max-width: none !important;
    width: 50%;
}

.modal-90p{
    max-width: none !important;
    width: 90%;
}

.modal-630{
    max-width: 630px !important;
    width: 630px;
}

.modal-1000{
    max-width: 1000px !important;
    width: 1000px;
}

.modal-1200{
    max-width: 1200px !important;
    width: 1200px;
}

.closeButton{
    margin: 0px !important;
    float: right;
}