.imenik_form {
    margin-bottom: 20px;
    max-width: 100%;
}

.imenik_search {
    width: 100%;
    margin: 0px;
    display: block;
    border: 1px solid #9f9f9f !important;
    padding: 10px 5px;
    color: #000 !important;
    background-color: #f5f6f8 !important;
    border-radius: 0;
}

.imenik_select {
    width: 100%;
    height: 100%;
    margin: 0px;
    display: block;
    border: 1px solid #9f9f9f !important;
    padding: 8px 5px;
    color: #000 !important;
    background-color: #f5f6f8 !important;
    border-radius: 0;
}
.imenik_search:focus {
    border: 1px solid #9f9f9f !important;
    border-radius: 0 !important;
    box-shadow: none;
}

.imenik_no_padding {
    padding: 0px;
}

.imenikModal {
    width: 1000px;
    max-width: 1000px;
}
.dropdown-menu {
    margin: 0;
    max-height: 500px;
    overflow-y: scroll;
}
.col {
    padding: 0
}