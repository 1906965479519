.admin_search {
    width: 100%;
    margin: 0px;
    display: block;
    border: 1px solid #9f9f9f !important;
    padding: 10px 5px;
    color: #000 !important;
    background-color: #f5f6f8 !important;
    border-radius: 0;
}

.admin_select {
    width: 100%;
    height: 100%;
    margin: 0px;
    display: block;
    border: 1px solid #9f9f9f !important;
    padding: 8px 5px;
    color: #000 !important;
    background-color: #f5f6f8 !important;
    border-radius: 0;
}

.modalLogs {
    max-width: 90%;
    min-width: 1000px;
}